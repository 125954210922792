export const MARGIN_SIZE = 48
export const MARGIN_SIZE_SM_DOWN = MARGIN_SIZE * .5
export const MED_DOWN_MEDIA_QUERY = "@media (max-width: 1080px)"
export const SMALL_DOWN_MEDIA_QUERY = "@media (max-width: 888px)"
export const XS_DOWN_MEDIA_QUERY = "@media (max-width: 640px)"
export const MAIN_CONTENT_MAX_WIDTH = 648
export const MAIN_CONTENT_MIN_WIDTH = 480
export const textColorDark = "#090E11"
export const textColorLight = "#4D4D4D"
export const strokeColorDark = "#272727"
export const strokeColorLight = "#BFBFBF"